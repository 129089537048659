<template src="./CreateResearchGroup.html"></template>

<script>
import categoryService from "@/services/Category";
import { putFile } from "@/services/files";
import idiService from "../../services/Idi";
import ModalDescriptionFile from "../../components/ModalDescriptionFile/ModalDescriptionFile";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateResearchGroup",
  components: {
    ModalDescriptionFile,
  },
  data() {
    return {
      creationRequest: {},
      categories: [],
      modalCreationRequest: false,
      institutionName: "",
      showModalImageDescription: false,
      researchers: [],
      institutionId: "",
      role: "",
      institutions: [],
      imageFile: null,
    };
  },
  validations: {
    institutionName: { required },
    creationRequest: {
      category: { required },
      description: { required },
      coordinator: { required },
      name: { required },
    },
    imageFile: { required },
  },
  created() {
    this.institutionName = this.$store.state.User.user.institutionId.name;
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.getAllInfo();
    this.infoResearchers();
  },
  methods: {
    deleteFile() {
      this.imageFile = null;
    },
    async getAllInfo() {
      try {
        this.categories = await categoryService.getCategories();
        this.institutions = await idiService.listInstitutions();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: CreateResearchGroup/getAllInfo()", e);
      }
    },
    async sendRequest() {
      this.$store.dispatch("fetchLoading", true);
      try {
        const urlImage = await putFile(
          this.imageFile,
          "CreateResearchGroup",
          `${this.imageFile.name}`
        );
        this.creationRequest.fileImg = urlImage;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error cargando el archivo",
          position: "is-bottom",
          type: "is-danger",
        });
      }
      try {
        let institution = 0;
        if (this.role === "University") {
          institution = this.institutionId;
        } else {
          institution = this.creationRequest.institution;
        }
        const obj = {
          name: this.creationRequest.name,
          description: this.creationRequest.description,
          registrationNumber: "",
          institutionId: institution,
          coordinatorId: this.creationRequest.coordinator.toString(),
          institutionName: this.institutionName,
          categoryId: this.creationRequest.category,
          fileImg: this.creationRequest.fileImg,
          descriptionImg: this.creationRequest.descriptionImg,
        };
        await idiService.createGroup(obj);
        this.$store.dispatch("fetchLoading", false);
        this.modalCreationRequest = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Grupo creado",
          position: "is-bottom",
          type: "is-success",
        });
        if (this.role === "University") {
          this.$router.push({ name: "UniversityResearchGroups" });
        } else {
          this.$router.push({ name: "ViewAllResearchGroups" });
        }
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: CreateResearchGroup/sendRequest()", e);
      }
    },
    openModalImageDescription() {
      this.showModalImageDescription = true;
    },
    closeModalDescriptionImage() {
      this.showModalImageDescription = false;
    },
    uploadFileInfo(info) {
      this.creationRequest.descriptionImg = info.description;
      this.imageFile = info.file;
      this.showModalImageDescription = false;
    },
    async infoResearchers() {
      try {
        this.researchers =
          await idiService.getUserTeacherInvestigatorByInstitution(
            this.institutionId
          );
      } catch (error) {
        console.error(error);
      }
    },
    async getResearchProfessor(id) {
      try {
        this.researchers =
          await idiService.getUserTeacherInvestigatorByInstitution(id);
        if (!this.researchers.length) {
          this.researchers = [{ nombres: "No hay resultados" }];
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidationAdmin() {
      return ["Administrator"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./CreateResearchGroup.scss">
</style>